var site = site || {};
site.Navigation = (function () {
    var navToggle = function() {
        $('.js-nav-toggle').click(function() {
            $(this).toggleClass('is-active');
        })
    }
    
    var stickyNav = function() {
        if(mediaQueries.lg.matches) {
            var utilityNavHeight =  $('.utility-nav').outerHeight();
            $('.js-sticky-nav').css('top', '-' + utilityNavHeight + 'px')
        } else {
            $('.js-sticky-nav').css('top', '0')
        }
    }
    
    var heroNavCollapse = function() {
        var collapseTrigger =  $('.js-hero-nav-collapse');
        if(mediaQueries.xl.matches) {
            collapseTrigger.attr('data-bs-toggle',  '');
        } else {
            collapseTrigger.attr('data-bs-toggle', 'collapse');
            collapseTrigger.click(function() {
                $('.js-hero-nav-toggle').toggleClass('d-none')
            })
        }
    }

    var heroNavTrigger = function() {
        $('.js-hero-nav-trigger').click(function() {
            if ($('.js-hero-nav').hasClass('minimized')) {
                $('.js-hero-nav-maximize').addClass('d-none');
                $('.js-hero-nav-minimize').removeClass('d-none');
            } else {
                $('.js-hero-nav-maximize').removeClass('d-none');
                $('.js-hero-nav-minimize').addClass('d-none');
            }
            $('.js-hero-nav').toggleClass('minimized');
        })
    }

    var megamenu = function() {
        $('.js-megamenu').bind('click', function (e) { e.stopPropagation() });
        if(mediaQueries.xl.matches) {
            $('.js-megamenu-toggle').click(function() {
                var thisToggle = $(this);
                var allToggles = $('.js-megamenu-toggle');
                if(thisToggle.hasClass('open')) {
                    allToggles.removeClass('open')
                    thisToggle.removeClass('open')
                } else {
                    allToggles.removeClass('open')
                    thisToggle.addClass('open')
                }
            });
            $(document).click(function() {
                $('.js-megamenu-toggle').removeClass('open');
            });
        }
    }
    
    const breadcrumbCheck = function() {
        const breadcrumbs = document.querySelector('.breadcrumbs'); 
        if (document.body.contains(breadcrumbs)) {
            document.body.classList.add('has-breadcrumbs');
        }
    }

    const stickyNavCheck = function() {
        let mobile = window.matchMedia('(max-width: 991px)');
        let stickyHeader = document.querySelector('.main-nav')
        if (mobile.matches) {
            window.onscroll = function() {
                let scrollDistance = $(window).scrollTop();
                if (scrollDistance > 0) {
                    document.body.classList.add('nav-stuck')
                } else {
                    document.body.classList.remove('nav-stuck')
                }
            }
        } else {
            const observer = new IntersectionObserver( 
                ([e]) => document.body.classList.toggle('nav-stuck', e.intersectionRatio < 1),
                {threshold: [1]}
            );
            observer.observe(stickyHeader);
        }
    }

    // Return
    return {
        // functionName : functionName
        navToggle : navToggle,
        stickyNav : stickyNav,
        stickyNavCheck : stickyNavCheck,
        heroNavCollapse : heroNavCollapse,
        heroNavTrigger : heroNavTrigger,
        megamenu : megamenu,
        breadcrumbCheck : breadcrumbCheck
    };
}());