var site = site || {};
site.Drawer = (function () {

    var checkForCheckedCompareButtons = function () {
        if ($('.js-compare-item').is(':checked')) {
            if ($('.compare-drawer').outerHeight() == 0) {
                $('.compare-drawer').animate({
                    'maxHeight': 350 + "px"
                });
            }
        }
    }

    var openDrawer = function () {
        $('.js-compare-item').on('click', function () {
            //reset
            $('.compare-card-container').html('');
            //build from storage
            var products = getProducts();
            if (products && products.length > 0) {
                $(products).each(function (i, e) {
                    setAddProductMarkup(e.slug, e.image, e.name);
                })
            }
            if ($('.compare-drawer').outerHeight() == 0) {
                $('.compare-drawer').animate({
                    'maxHeight': 350 + "px"
                });
            }
        })
    }
    var closeDrawer = function () {
        $('.js-close-drawer').on('click', function () {
            $('.compare-drawer').animate({
                'maxHeight': 0
            });
        })
    }
    var setAddProductMarkup = function (slug, image, name) {

        $('.compare-card-container').append('<div id="compare-' + slug + '" class="col-4" data-slug="' + slug + '">' +
            '<div class="compare-card product-drawer text-center">' +
            '<button type="button" class="compare-card-remove-product text-white js-remove-product" data-dismiss="drawer" aria-label="Close"><i class="fas fa-times"></i></button>' +
            '<img class="" src="' + image + '" alt="">' +
            '<p class="text-16">' + name + '</p>' +
            '</div>' +
            '</div>')

    }
    var productAddAndRemove = function () {
        $('.js-compare-item').on('change', function (e) {
            var image = $(this).data('img');
            var name = $(this).data('name');
            var slug = $(this).data('slug');
            if (this.checked) {
                if (compareProductsCount() < 3) {
                    if (!productInList(slug)) {
                        setAddProductMarkup(slug, image, name);
                    }

                    storeProduct(slug, image, name);
                    $('.js-max-reached').addClass('d-none');
                } else {
                    $('.js-max-reached').removeClass('d-none');
                    e.preventDefault();
                    this.checked = false;
                }
            } else {
                $('#compare-' + slug).remove();
                removeProduct(slug);
            }
        })
    }

    var removeProductFromDrawer = function () {
        $(document).on('click', '.js-remove-product', function () {
            $(this).closest('.col-4').remove();
            var slug = $(this).parent().parent().attr('data-slug');
            $('#' + slug + '-Checkbox').prop("checked", false);
            removeProduct(slug);
        })
    }
    var getProducts = function () {
        var localData = localStorage.getItem('productCompare');
        if (localData) {
            return JSON.parse(localData);
        }
        return [];
    }
    var storeProduct = function (slug, image, name) {
        var products = getProducts();
        if (!productInList(slug)) {
            if (products.length > 0) {
                products.push(product(slug, image, name));
                localStorage.setItem('productCompare', JSON.stringify(products));
            } else {
                products.push(product(slug, image, name));
                localStorage.setItem('productCompare', JSON.stringify(products));
            }
        }
    }

    var productInList = function (slug) {
        var products = getProducts();        
        for (var i = 0; i < products.length; i++) {
            if (products[i].slug == slug) {
                return true;
            }
        }
        return false;
    }
    var product = function (slug, image, name) {
        return {
            slug: slug,
            image: image,
            name: name
        };
    }
    var removeProduct = function (slug) {
        var products = getProducts();
        if (products.length > 0) {
            var productsToKeep = products.filter(function (value, index, arr) { return value.slug != slug });
            localStorage.setItem('productCompare', JSON.stringify(productsToKeep));
        } else {
            products.push(product(slug, image, name));
            localStorage.setItem('productCompare', JSON.stringify(products));
        }
        $('.js-max-reached').addClass('d-none');
    }
    var compareProductsCount = function () {
        var localData = localStorage.getItem('productCompare');
        if (localData) {
            var data = JSON.parse(localData);
            return data.length;
        }
        return 0;
    }
    var generateLink = function () {
        var products = getProducts();
        if (products && products.length > 1) {
            var slugs = products.map(function (e) {
                return e.slug;
            }).join(",");
            window.location.href = "/products/product-comparison?slugs=" + slugs;
            return true;
        } else {
            $('#compareText').addClass('alert');
        }
    }
    return {
        checkForCheckedCompareButtons: checkForCheckedCompareButtons,
        openDrawer: openDrawer,
        productAddAndRemove: productAddAndRemove,
        closeDrawer: closeDrawer,
        removeProductFromDrawer: removeProductFromDrawer,
        generateLink: generateLink,
        getProducts: getProducts,
        productInList: productInList
    }

}());