$(document).ready(function () {

    // Trigger for toasts
    const toastTrigger = document.getElementById('cartToastBtn');
    const liveToast = document.getElementById('cartToast');
    if (toastTrigger) {
        toastTrigger.addEventListener('click', () => {
            const toast = new bootstrap.Toast(liveToast, {
                delay: 3000
            });
            toast.show();
        });
    };

})