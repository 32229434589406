var site = site || {};
site.Utilities = (function () {

    //#region Functions

    var backgroundImageElement = function () {
        $('.js-bg-image').css('background-image', function () {
            var bgUrlEncode = encodeURI($(this).data("bg-img"));
            var bg = 'url("' + bgUrlEncode + '")';
            return bg;
        });
    }

    var partImageToggle = function () {
        $('.js-part-image').attr('src', $('.js-part:checked').data('img'));

        $('.js-part').on('change', function () {
            if (this.checked) {
                $('.js-part-image').attr('src', $(this).data('img'));
            }
        })
    }

    function stopVideoOnModalClose() {
        $("#productVideoModal, .js-close").click(function () {
            var thisModalVideo = $('#productVideoModal').find('iframe');
            var videoSrc = thisModalVideo.attr('src');
            //sets src to nothing, stops the video
            thisModalVideo.attr("src", "");
            thisModalVideo.attr("src", videoSrc);
        });
    }

    var bindNumericConstraints = function () {
        $(".numeric-input").on("keypress", function (e) {
            if (isNaN(e.key)) e.preventDefault();
        });
    }


    var itemsInYourCartModal = new bootstrap.Modal(document.getElementById("itemsInYourCartModal"), { backdrop: 'static' });

    var backToClassicPrep = function () {
        $("#backToClassicSiteBannerLink").click(function () {
            // If there are items in your cart, show the modal, otherwise just forward on.
            var hasCartItems = false;
            let name = "CartCount=";
            let cookies = document.cookie.split(';');

            for (var i = 0; i < cookies.length; i++) {
                var c = cookies[i];

                while (c.charAt(0) == ' ') {
                    c = c.substring(1, c.length);
                }

                if (c.indexOf(name) == 0) {
                    //cookie exists, check for 0 == have items in cart
                    if (c.substring(10, 11) != 0) {
                        hasCartItems = true;
                    }
                }
            }

            if (hasCartItems) {
                itemsInYourCartModal.show();
            }
            else {
                window.location = '/Home/BackToClassic';
            }
        });

        $("#returnToOriginalSiteAnyway").click(function () {
            window.location = '/Home/BackToClassicPartList';
        });

        $(".cancelItemsInYourCartModal").click(function () {
            $("#itemsInYourCartModal").hide();
        });
    }


    /**
     * Responsible for displaying a fixed and centered popover message.
     * @param {ShowCenteredPopupMessageRequest} request
     * @returns {ShowCenteredPopupMessageResponse}
     */
    var showCenteredPopupMessage = function (request) {
        var alertHtml = `<div class="sp-centered-popup-message position-fixed alert shadow ${request.alertClass} ${request.alertTextClass} z-popover"><div class="d-flex flex-nowrap align-items-start justify-content-start"><div class="flex-fill text-center"><i class="mr-1 fa ${request.iconClass}"></i><span>${request.message}</span></div><i class="dismiss-alert fas fa-times float-right"></i></div></div>`;
        var $alert = $(alertHtml);
        var timeout = null;
        $alert.appendTo($("body"));

        var dismissedPromise = null;
        var dismissedPromiseResolveFn = null;

        dismissedPromise = new Promise(function (resolve, reject) {
            dismissedPromiseResolveFn = resolve;
        });

        var dismiss = function () {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            // Fade out the alert before removing it.
            $alert.fadeOut(200, function () {
                $alert.remove();
                dismissedPromiseResolveFn();
            });
        }

        $alert.one("click", ".dismiss-alert", function (evt) {
            dismiss();
        });

        if ((request.timeout ?? 0) > 0) {
            timeout = setTimeout(dismiss, request.timeout);
        }

        return {
            dismiss: dismiss,
            popupDismissed: dismissedPromise,
        };
    }

    $('.dropdown-link').click(function () {
        if ($(this).attr('aria-expanded') == "false") {
            $(this).find('.js-chevron').attr('data-icon', 'angle-down').removeClass('fa-angle-up').addClass('fa-angle-down');
        }
        else if ($(this).attr('aria-expanded') == "true") {
            $(this).find('.js-chevron').attr('data-icon', 'angle-up').removeClass('fa-angle-down').addClass('fa-angle-up');
        }
    });

    //#endregion
   

    //var refreshMobileListView = function () {
    //    $("[data-add-to-cart-container-type='list']").each(function () {
    //        // Check if the record had a blocking restriction that is
    //        // NOT the max quantity.
    //        const $this = $(this);
    //        const $quantityElement = $this.find(".quantity-select");
    //        let selected = $quantityElement.val();

    //        if (typeof (selected) === "string") {
    //            selected = parseInt(selected);
    //        }

    //        if (isNaN(selected)) {
    //            selected = 0;
    //        }

    //        const restrictionData = determineAddToCartElementsRestrictions($quantityElement, selected);
    //        displayBtnAndMessages(restrictionData);
    //    });
    //}
   

    //$(function () {
    //    // This event listener reacts to the resize event and will update the
    //    // list view for mobile accordingly.
    //    mediaQueries.xl.addEventListener("change", function () {
    //        refreshMobileListView();
    //    });

    //    // This event listener reacts to whenever the search results refresh
    //    // and will also update the list for view mobile accordingly.
    //    if (window.site.ProductGridComponent) {
    //        window.site.ProductGridComponent.addEventListener(
    //            "html-updated",
    //            refreshMobileListView
    //        );
    //    }
    //});

    //// Prevents the mobile nav submenus and backdrop from remaining
    //// visible when the mobile nav is hidden and header nav is active
    //$(function () {
    //    mediaQueries.lg.addEventListener("change", function () {
    //        $('.offcanvas-backdrop').offcanvas('hide');
    //        $('.offcanvas-bottom').offcanvas('hide');
    //    })
    //});


    // Return
    return {
        backgroundImageElement: backgroundImageElement,
        partImageToggle: partImageToggle,
        stopVideoOnModalClose: stopVideoOnModalClose,
        bindNumericConstraints: bindNumericConstraints,
        backToClassicPrep: backToClassicPrep,
        showCenteredPopupMessage: showCenteredPopupMessage,
    };
}());