// Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
// for details on configuring this project to bundle and minify static web assets.

// Write your JavaScript code.
//----------------------------------------------------------------------------//
//  GLOBALS
//----------------------------------------------------------------------------//
const mediaQueries = {
    xs: window.matchMedia('(max-width: 575px)'),
    sm: window.matchMedia('(min-width: 576px)'),
    md: window.matchMedia('(min-width: 768px)'),
    lg: window.matchMedia('(min-width: 992px)'),
    xl: window.matchMedia('(min-width: 1200px)'),
    xxl: window.matchMedia('(min-width: 1600px)'),
    mapxxl: window.matchMedia('(min-width: 1500px)')
}

// NOTE: This code was added so that the gulp task generateDeclarationFiles
// would pick up this variable on the global site variable.
var site = site || {};
/**@type {import("vanilla-lazyload").ILazyLoadInstance}*/
site.lazyLoadInstance = null;

$(function () {
    // Bootstrap matching Media Queries
    //To use:
    //      Pass mediaQueries into module function: extranet.Utilities.function(mediaQueries)
    //      In module function:
    //          if(mediaQueries.sm.matches){
    //              code here
    //          }

    //------------------------------------------------------------------------//
    //  Initialize Lazyloading with support for native lazyload
    //
    //  Defaults to use lazyload plugin, but will fall back to native support 
    //  for browsers with support for the loading="lazy" attribute
    //  (https://caniuse.com/#feat=loading-lazy-attr)
    //------------------------------------------------------------------------//
    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        use_native: true,
    });

    site.lazyLoadInstance = lazyLoadInstance;

    //------------------------------------------------------------------------//
    //  PUBLIC MODULE FUNCTIONS
    //------------------------------------------------------------------------//
    //  Usage Examples: 
    //      site.Utilities.functionName()
    //      site.Utilities.functionName(mediaQueries)
    //------------------------------------------------------------------------//
    site.Utilities.partImageToggle();
    site.Utilities.backgroundImageElement();
    site.Utilities.stopVideoOnModalClose();
    site.Utilities.bindNumericConstraints();
    site.Utilities.backToClassicPrep();
    site.Navigation.stickyNav();
    site.Navigation.stickyNavCheck();
    site.Navigation.megamenu();
    site.Navigation.breadcrumbCheck();
    site.Navigation.heroNavCollapse()
    site.Navigation.heroNavTrigger()
    site.Navigation.navToggle();
    site.Accordion.toggleIcon();
    site.Drawer.checkForCheckedCompareButtons();
    site.Drawer.openDrawer();
    site.Drawer.productAddAndRemove();
    site.Drawer.closeDrawer();
    site.Drawer.removeProductFromDrawer();
    // Check DOM for images loaded via dynamically and update Lazyload instance
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }

    $("a[href='/Account/Signout']").on("click", function (evt) {
        // Clear all cached pages.
        localStorage.clear();
    });

    // Intercept jquery.ajax requests that respond with a 302 (redirect to the
    // login page) and notify the user that they've been logged out and need to
    // login again.
    $(document).on("ajaxComplete", function (/** @type {JQuery.Event} */event, /** @type {JQuery.jqXHR} */ xhr, settings) {
        const hasRequiresLoginHeader = (xhr.getResponseHeader("X-Requires-Login") ?? "").length > 0;
        const alreadyOnLoginPage = window.location.pathname.startsWith("/login");

        if (!alreadyOnLoginPage && hasRequiresLoginHeader) {
            // Redirect to the login page.
            const returnUrl = window.location.pathname + window.location.search;
            const newUrl = window.location.origin + "/login?returnUrl=" + returnUrl;
            window.location.href = newUrl;
        }
    });

    // Intercept fetch requests that respond with a 302 (redirect to the login
    // page) and notify the user that they've been logged out and need to
    // login again.
    const {fetch: origFetch} = window;
    window.fetch = async (...args) => {
        const response = await origFetch(...args);
        const hasRequiresLoginHeader = response.headers.has("X-Requires-Login")
        const alreadyOnLoginPage = window.location.pathname.startsWith("/login");

        if (!alreadyOnLoginPage && hasRequiresLoginHeader) {
            // Redirect to the login page.
            const returnUrl = window.location.pathname + window.location.search;
            const newUrl = window.location.origin + "/login?returnUrl=" + returnUrl;
            window.location.href = newUrl;
        }

        return response;
    };

    var userGuidingInit = function () {
        $.ajax({
            method: "GET",
            url: "/Customer/GetCustomerID",
            dataType: "json",
            contentType: "application/json"
        })
            .done(function (data) {
                if (data != null) {
                    window?.userGuiding?.identify(data)
                }
            })
            .fail(function (data) {
                console.log('Failed to get CustomerID: ' + data);
            });
    }

    userGuidingInit();

    function setGlobalNotificationExpanded() {
        const value = localStorage.getItem('GlobalNotificationExpanded');

        if (value === null) {
            localStorage.setItem('GlobalNotificationExpanded', JSON.stringify(false));
            getGlobalNotificationExpanded();
        }
        else {
            var globalNotificationVisible = localStorage.getItem('GlobalNotificationExpanded') == 'true' ? true : false;
            localStorage.setItem('GlobalNotificationExpanded', JSON.stringify(!globalNotificationVisible));
            getGlobalNotificationExpanded();
        }
    }

    function getGlobalNotificationExpanded() {
        const value = localStorage.getItem('GlobalNotificationExpanded');
        if (value === 'true' || value === null) {
            $('#global-notification').addClass('show');
        }
        else if (value === 'false') {
            $('#global-notification').removeClass('show');
        }
    }

    $(document).ready(function () {
        getGlobalNotificationExpanded();
        $(document).on('click', '[data-parent="#global-notification-accordion"]', function (e) {
            setGlobalNotificationExpanded();
        });
        
    });



    //------------------------------------------------------------------------//
    // WINDOW RESIZE FUNCTIONS
    //------------------------------------------------------------------------//
    $(window).smartresize(function () {
        //--------------------------------------------------------------------//
        //  Public module functions
        //--------------------------------------------------------------------//
        //  Usage Examples: 
        //      site.Utilities.functionName()
        //      site.Utilities.functionName(mediaQueries)
        //--------------------------------------------------------------------//
        site.Navigation.stickyNavCheck();
        // site.Navigation.breadcrumbCheck();
        site.Navigation.stickyNav();
        site.Navigation.heroNavCollapse()
    }, 200);
});