var site = site || {};
site.Accordion = (function () {
    
    var toggleIcon = function () {
		$(document).on('click', '.js-toggle-accordion', function () {
            
			var accordionItem = $(this);
			if(accordionItem.attr('aria-expanded') == "true"){
				accordionItem.find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
			} else {
				accordionItem.find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
			}
        })
	}

	var checkIcons = function () {
		$('.js-toggle-accordion').each(function () {
			var accordionHead = $(this);
			var accordionBody = accordionHead.attr('href');
			var result =$(accordionBody).hasClass('show');
			if (result) {
				$(accordionHead).find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
			} else {
				$(accordionHead).find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
            }
		})
		
	}


    return {
		toggleIcon: toggleIcon,
		checkIcons: checkIcons
    }

}());